import { get1xUsageBooking } from '../util/api';

// Action types
const INITIATE_1X_USAGE_BOOKING_REQUEST = 'app/user1xUsage/INITIATE_1X_USAGE_BOOKING_REQUEST';
const INITIATE_1X_USAGE_BOOKING_SUCCESS = 'app/user1xUsage/INITIATE_1X_USAGE_BOOKING_SUCCESS';
const INITIATE_1X_USAGE_BOOKING_ERROR = 'app/user1xUsage/INITIATE_1X_USAGE_BOOKING_ERROR';

// Initial state
const initialState = {
  initiate1xUsageBookingInProgress: false,
  initiate1xUsageBookingError: null,
  isValid1xUsage: null,
};

// Action creators
export const initiate1xUsageBookingRequest = () => ({
  type: INITIATE_1X_USAGE_BOOKING_REQUEST,
});

export const initiate1xUsageBookingSuccess = () => ({
  type: INITIATE_1X_USAGE_BOOKING_SUCCESS,
});

export const initiate1xUsageBookingError = error => ({
  type: INITIATE_1X_USAGE_BOOKING_ERROR,
  payload: error,
});

// Thunk action creator
export const initiate1xUsageBookingValidator = userId => (dispatch, getState, sdk) => {
  dispatch(initiate1xUsageBookingRequest());

  return get1xUsageBooking({ userId })
    .then(() => {
      dispatch(initiate1xUsageBookingSuccess());
    })
    .catch(e => {
      dispatch(initiate1xUsageBookingError(e));
    });
};

// Reducer
export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case INITIATE_1X_USAGE_BOOKING_REQUEST:
      return {
        ...state,
        initiate1xUsageBookingInProgress: true,
        initiate1xUsageBookingError: null,
      };
    case INITIATE_1X_USAGE_BOOKING_SUCCESS:
      return {
        ...state,
        initiate1xUsageBookingInProgress: false,
        initiate1xUsageBookingError: null,
        isValid1xUsage: true,
      };
    case INITIATE_1X_USAGE_BOOKING_ERROR:
      return {
        ...state,
        initiate1xUsageBookingInProgress: false,
        initiate1xUsageBookingError: payload,
        isValid1xUsage: false,
      };
    default:
      return state;
  }
}

// Selector
export const isValid1xUsage = state => state.user1xUsage.isValid1xUsage;
